import React, { useCallback, useState } from 'react';
import { Link } from 'gatsby';
import Icon from '@mdi/react';
import { mdiMenuOpen as menuOpen, mdiMenu as menuClose } from '@mdi/js';
import { isBrowser } from '../../../utils';

import Image from '../atoms/image';
import Navigation from '../molecules/navigation';

const mainNavigationClass = 'main-navigation';

export const mainNavigationScrollHandler = (e) => {
  if (!isBrowser()) return;

  if (!!document.querySelector(`.${mainNavigationClass}`)) {
    if (window.scrollY > 0) {
      return document.querySelector(`.${mainNavigationClass}`).classList.add(`${mainNavigationClass}--scrolled`);
    }

    document.querySelector(`.${mainNavigationClass}`).classList.remove(`${mainNavigationClass}--scrolled`);
  }
};

const MainNavigation = ({ navLinks, pageType, className, isMobile }) => {
  const [isEnabled, setIsEnabled] = useState(false)

  const menuHandler = useCallback(() => {
    setIsEnabled(() => !isEnabled)
  }, [isEnabled])

  return (
    <div className={[
      mainNavigationClass,
      className,
      (pageType ? ` is-${pageType}-page` : ''),
      (isEnabled ? `${mainNavigationClass}--enabled` : '')
    ].join(' ').trim()}>
      <div className={`${mainNavigationClass}__wrapper`}>
        <Link to={'/'} className="logo__link">
          {
            isMobile
              ? <Image src="logo--mobile.png" className="logo__wrapper" />
              : <Image src="logo.png" className="logo__wrapper" />
          }
        </Link>
        {isMobile ? <div className="mobile-menu">
          <button className="menu-toggle-btn" onClick={menuHandler}>
            <Icon path={isEnabled ? menuOpen : menuClose} size={1} />
          </button>
        </div> : <></>}
        {navLinks && <Navigation links={navLinks} />}
      </div>
    </div>
  )
};

export default MainNavigation;
