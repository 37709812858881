import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { getCurrentPageProps, getPath, getPageHash, scrollToHash, isBrowser } from '../../../utils';

import '../../../styles/index.scss';

import HOC from '../organisms/root';
import Grid, { GridRow, GridCol } from '../atoms/grid';
import { HeroContainer, ContentContainer, ContentWrapper } from '../molecules/container';
import MainNavigation from '../organisms/mainNavigation';
import { Devider, Spacer } from '../atoms/devider';
import Header from '../molecules/header';
import Footer from '../molecules/footer';
import { H1, H2 } from '../atoms/header';
import Address from '../atoms/address';
import { DefinitionList } from '../atoms/definitionList';
import CookiesTooltip from '../atoms/cookiesTooltip';
import Image from '../atoms/image';

const isClient = typeof window !== 'undefined';

class Layout extends Component {
  state = {
    viewportWidth: isBrowser() ? window.innerWidth : 0,
    isMobile: false,
  };

  componentDidMount() {
    if (isClient) {
      this.updateWindowDimensions();
      window.addEventListener('resize', this.updateWindowDimensions);
    }

    setTimeout(() => {
      !!getPageHash(getPath()) && scrollToHash(getPageHash(getPath()))
      this.updateWindowDimensions()
    }, 100);
  }

  componentWillUnmount() {
    if (isClient) window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    this.setState({
      viewportWidth: window.innerWidth,
      isMobile: window.innerWidth <= 768,
    });
  }

  render() {
    const { children, data } = this.props;

    const onClickHandler = (e, el) => {
      if (isClient) {
        e.preventDefault();
        window.localStorage.setItem('cookiesConsent', JSON.stringify({ isOk: true }));
        const cookieConsentEl = document.querySelector('.cookies-consent')

        if (cookieConsentEl) {
          cookieConsentEl.remove()
        }
      }
    }

    return data ? (
      <HOC
        googleAnalyticsId={data?.siteConfigs?.tracking?.ga?.id}>
        {children}
        <div className={[
          'layout__wrapper',
          (this.state.isMobile ? 'is-mobile' : ''),
        ].join(' ').trim()} style={{ zIndex: 10 }}>
          <MainNavigation isMobile={this.state.isMobile} className={[
            (this.state.isMobile && 'mobile-navigation' || '')
          ].join(' ').trim()} navLinks={data.mainNavLinks} pageType="main" />
          <Grid noPadding={true}>
            <GridRow>
              <GridCol direction="column">
                <Header>
                  <HeroContainer
                    contentFeatured={getCurrentPageProps(data.heroFeatured, getPath())} />
                </Header>
                <GridRow direction="row">
                  <GridCol>
                    <Grid gap={10} noPadding={false}>
                      <H1 type="section">{(getCurrentPageProps(data.contentContainer, getPath(), "aboutUs"))?.title}</H1>
                      <GridRow direction="row">
                        <GridCol colSize={4}>
                          <a anchor='aboutus' name='aboutus'></a>
                          <ContentContainer content={getCurrentPageProps(data.contentContainer, getPath(), "mission")} />
                        </GridCol>
                        <GridCol colSize={4}>
                          <ContentContainer
                            direction="column-reverse"
                            content={getCurrentPageProps(data.contentContainer, getPath(), "vision")}
                          />
                        </GridCol>
                      </GridRow>
                    </Grid>
                  </GridCol>
                </GridRow>
                {
                  data?.projects &&
                  <Grid gap={10} noPadding={false}>
                    <Devider margin={10} top={false} />
                    <a anchor='projects' name='projects'></a>
                    <H1 type="section">{data.projects.title}</H1>

                    <GridRow direction="row" isWrapped={true}>
                      {
                        data.projects.items.map((item, index) => {
                          return (
                            <GridCol colSize={6} key={`research-item-${index}`}>
                              <ContentContainer content={item} />
                            </GridCol>
                          )
                        })
                      }
                    </GridRow>
                  </Grid>
                }
              </GridCol>
            </GridRow>
            <GridRow>
              <GridCol>
                <Grid gap={0} noPadding={false}>
                  <Devider margin={10} top={false} />
                  <GridRow direction="row" isWrapped={true}>
                    <GridCol colSize={12}>
                      <ContentContainer content={data.income} />
                    </GridCol>
                  </GridRow>
                </Grid>
              </GridCol>
            </GridRow>
            <GridRow>
              <GridCol>
                <Grid gap={0} noPadding={false}>
                  <Devider margin={10} top={false} />
                  <GridRow direction="row" isWrapped={true}>
                    {
                      data.management.items.map((item, index) => {
                        return (
                          <GridCol colSize={6} key={`management-item-${index}`}>
                            <ContentContainer content={item} />
                          </GridCol>
                        )
                      })
                    }
                  </GridRow>
                </Grid>
              </GridCol>
            </GridRow>
            <GridRow>
              <GridCol>
                <Grid gap={0} noPadding={false}>
                  <Devider margin={10} top={false} />
                  <a anchor='activities' name='activities'></a>
                  <H1 type="section">{data.activities.title}</H1>
                  <GridRow direction="row" isWrapped={true}>
                    {
                      data.activities.items.map((item, index) => {
                        return (
                          <GridCol colSize={6} key={`management-item-${index}`}>
                            <ContentContainer content={item} />
                          </GridCol>
                        )
                      })
                    }
                  </GridRow>
                </Grid>
              </GridCol>
            </GridRow>
            <GridRow>
              <GridCol>
                <Grid gap={0} noPadding={false}>
                  <Devider margin={10} top={false} />
                  <a anchor='spendings'></a>
                  <H1 type="section">{data.spendings.sectionTitle}</H1>
                  <ContentContainer content={data.spendings} />
                  <GridRow direction="row" isWrapped={true}>
                    {
                      data.spendings.items.map((item, index) => {
                        return (
                          <ContentContainer content={item} key={`spendings-item-${index}`} />
                        )
                      })
                    }
                  </GridRow>
                </Grid>
              </GridCol>
            </GridRow>
          </Grid>
        </div>
        <a anchor='contact' name='contact'></a>
        <Grid className="footer__container">
          <GridRow direction="row" isGrow={true}>
            <GridCol colSize={6}>
              <Grid>
                <GridRow direction="row" isGrow={true}>
                  <GridCol className="footer__logo-wrapper" colSize={3}>
                    <Image src="icon.png" className="footer__logo" />
                  </GridCol>
                  <GridCol colSize={6}>
                    <ContentWrapper theme="dark">
                      <H2>{data.footerTitle}</H2>
                      <Address content={getCurrentPageProps(data.contactInfo, '*')} className="address" />
                    </ContentWrapper>
                  </GridCol>
                  <GridCol colSize={6}>
                    <ContentWrapper theme="dark">
                      <Spacer spacing={12} />
                      <DefinitionList prefixed={true} content={getCurrentPageProps(data.contactInfo, '*')} className="definition-list--dark" />
                    </ContentWrapper>
                  </GridCol>
                  <GridCol colSize={6} className="footer__anbi-wrapper">
                    <Image src="anbi-logo.jpg" className="footer__logo" />
                    <div className="anbi-label">
                      Stichting BF is a Public Benefit Organisation
                    </div>
                  </GridCol>
                </GridRow>
              </Grid>
            </GridCol>
          </GridRow>
          <GridRow direction="row" isGrow={true}>
            <GridCol stretched={true}>
              <Footer
                title={data.footerTitle}
                navLinks={{ items: data.footerNavLinks.items }}
                ctaLinks={{ items: data.footerNavLinks.ctas }}
              />
            </GridCol>
          </GridRow>
          <Image src="icon--watermark-blurred.png" className="footer__logo--watermark" />
        </Grid>
        <CookiesTooltip>
          <ContentContainer content={data.cookiesConsent} refs={[{ icon: 'close', onClick: onClickHandler }]} />
        </CookiesTooltip>
      </HOC>
    ) : (
      <>
        No Data
      </>
    );
  }
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
