import React, { useLayoutEffect, useRef, useState } from 'react';
import { Link as GLink } from 'gatsby';
import { scrollTo } from '../../../utils';

const Link = ({ refs, target, icon, label, to, type, fullWidthCtas, children, className, ...props }) => {
  const ref = useRef(null);
  const iconClass = icon ? ' link--has-icon' : '';
  const buttonClass = type && type === 'button' ? ' link--cta link--block' : '';
  const isFullWidth = fullWidthCtas ? ' link--fullwidth' : '';
  const isExternalLink = to && to.indexOf("://") > 0 ? true : false;
  const isBlankTarget = target === '_blank' ? true : false;
  const isAnchorLink = to && to.indexOf("#") === 0 ? true : false;
  const clickHandler = isAnchorLink ? {
    onClick: scrollTo
  } : {};

  const [onClickHandler, setOnClickHandler] = useState(null)

  useLayoutEffect(() => {
    if (refs && icon) {
      const { onClick } = refs.find(({ icon: refsIcon }) => refsIcon === icon) || {}

      if (onClick) {
        setOnClickHandler(() => onClick)
      }
    }
  }, [ref.current])

  return (
    isBlankTarget || isAnchorLink || isExternalLink ?
      <a
        ref={ref}
        className={`link ${className || ''}${iconClass}${buttonClass}${isFullWidth}`}
        title={label}
        target={target}
        label={label}
        type="button"
        text={label}
        icon={icon}
        href={to}
        {...clickHandler}
        {...(onClickHandler ? { onClick: (e) => onClickHandler(e, ref?.current) } : {})}
        {...props}>{children}</a>
      :
      <GLink
        className={`link ${className || ''}${iconClass}${buttonClass}${isFullWidth}`}
        title={label}
        label={label}
        icon={icon}
        to={to}
        text={label}
        {...props}>{children}</GLink>
  );
};

export default Link;
