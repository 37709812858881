import React from 'react';

import Navigation from '../molecules/navigation';

const Footer = ({ title, navLinks, ctaLinks, className }) => (
  <footer className={`footer__wrapper ${className || ''}`.trim()}>
    {navLinks?.items?.length && <div className="footer-col footer-col-nav">
      <Navigation links={navLinks} className="footer-nav footer-nav-links" />
    </div>}
    {ctaLinks?.items?.length && <div className="footer-col footer-col-cta">
      <Navigation links={ctaLinks} className="footer-cta footer-cta-links" />
    </div>}
    <div className="footer-col footer-col-title">
      <span className="footer__label">
        ©{title} - {new Date().getFullYear()}
      </span>
    </div>
  </footer>
);

export default Footer;
