import * as $C_ from '../../../content/constants';

export const getConstant = (key, returnKey = true) => {
  return $C_ && $C_[key] ? $C_[key] : returnKey ? `__${key}__` : undefined;
};

export const getCurrentPageProps = (data, path, selector = undefined) => {
  return data
    && data[path] ?
    selector ? data[path][selector] : data[path]
    : undefined;
};

export const getPageData = (data, type, slug) => data
  && data.pages && data.pages[type] && data.pages[type].find((pageData) => pageData.slug === slug);

export const isBrowser = () => typeof window !== 'undefined';

export const getPath = () => {
  return isBrowser() ? window.location.pathname : '';
};

export const isHomePath = (url) => {
  return isBrowser() ? url === '/' ? true : false : false;
};

export const fixMainNavigationItemLink = (url, currentPath) => {
  if (isHomePath(currentPath)) {
    return url = url.indexOf('#') >= 0 && url.indexOf('/') === 0 ? url.substr(1) : url;
  }
  return url;
}

export const getPageHash = () => {
  return isBrowser() ? window.location.hash : '';
};

export const resetHash = () => {
  if (isBrowser()) {
    const noHashURL = window.location.href.replace(/#.*$/, '');
    window.history.replaceState('', document.title, noHashURL);
  }
};

export const createMarkup = (__html) => ({ __html });

export const go = (to) => isBrowser() && window.open(to);

export const chunkArray = ([arr, size]) => {
  return [
    Array.from({ length: Math.ceil(arr.length / size) }, (v, i) =>
      arr.slice(i * size, i * size + size)
    ), size
  ];
};

export const fillChunkedArray = ([arr, size]) => {
  return [arr.map(chunk => {
    if (chunk.length < size) {
      for (let i = chunk.length; i < size; i++) {
        chunk.push('');
      }
    }
    return chunk;
  }), size];
};

export const pipe = (...fns) => x => fns.reduce((v, f) => f(v), x);

export const scrollHandler = (target, callback) => {
  if (isBrowser() && target) {
    target.addEventListener('scroll', debounce(callback, 10));
  }
};

export const detachEventListeners = (events) => {
  if (isBrowser() && events && events.length > 0) {
    events.map(({ target, event, callback }) => {
      return event && target.removeEventListener(event, callback);
    });
  }
};

export const scrollTo = (e) => {
  e.preventDefault();
  const { hash } = e.currentTarget;
  scrollToHash(hash);
};

export const scrollToHash = (hash) => {
  if (isBrowser() && document && document.querySelector(`.main-navigation`)) {
    const target = document.querySelector(`a[anchor="${hash.substr(1)}"]`);
    const navigationHeight = 0 //document.querySelector(`.main-navigation`).offsetHeight;
    if (isBrowser() && target) {
      const { offsetTop } = target;
      if (hash && offsetTop) {
        window.scrollTo({
          top: offsetTop - (navigationHeight + 10),
          behavior: 'smooth'
        });
      }
    }
  }
};

export const debounce = (fn, time) => {
  let timeout;

  return function () {
    const functionCall = () => fn.apply(this, arguments);

    clearTimeout(timeout);
    timeout = setTimeout(functionCall, time);
  }
};

export const isMobile = () => {
  if (isBrowser()) {
    return Boolean((typeof window.navigator === "undefined" ? "" : navigator.userAgent).match(
      /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i
    ))
  }
};
